/* eslint-disable @typescript-eslint/camelcase */
import ApiClient from '../../api/client';
import { ZidShipTypesServiceLevelResponseInterface } from '../types/service-level-response.interface';
import { ZidShipTypesPagesResponseInterface } from '../types/pages-response.interface';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import { zidShipApiUrls } from './urls';
import {
  ApiBaseDataResponseObjectType,
  ApiBaseMessageResponseObjectType,
  ApiBaseResponseObjectType,
  ApiBaseResponseType,
  ApiDescriptionDataResponseObjectType,
  ApiResponseType,
  ApiStatusResponseObjectType,
} from '../../api/types';
import { urlQueryFormat } from '../../common/helpers/url/query/format';
import { ZidShipTypesWalletTransactionsResponseInterface } from '../types/wallet-transactions-reponse.interface';
import { ZidShipTypesWalletFiltersInterface } from '../types/wallet-filters.interface';
import { ZidShipTypesWalletFiltersToSearchParams } from '../types/wallet-filters-to-search.interface';
import { ZidShipTypesSelectedServiceLevels } from '../types/selected-service-levels';
import { ZidShipTypesModelServiceActivationDataInterface } from '../types/model/service-activation-data.interface';
import { ZidShipTypesServiceActivateRequestInterface } from '../types/service-activate-request.interface';
import { ZidShipTypesModelConfigurationsInterface } from '../types/model/configurations.interface';
import { ZidShipTypesModelConfigurationsValidationInterface } from '../types/model/configurations-validation.interface';
import { ReturnedShipmentInfoInterface } from '../../api/model/order/returned-shipment-info.interface';
import { ZidShipTypesWalletBalance } from '../types/wallet-balance';
import { VerifyOtpResponseInterface } from '../types/verify-otp-response.interface';
import { PurchasedResponseType } from '../types/purchased-response.type';
import { ZidShipTypesShipmentsFilterInterface } from '../types/shipments-filters.interface';
import { ZidShipShipmentsPaginationInterface } from '../types/model/shipment.interface';
import { ZidshipTypesShipmentsFiltersDateInterface } from '../types/shipments-filters-data.interface';
import { ZidShipTypesModelServiceLevelInterface } from '../types/model/service-level.interface';

import Catch from '../../common/decorators/catch-error';
import { ZidShipTypesModelServiceLevelCourierInterface } from '../types/model/service-level-courier.interface';
import { ZidShipTypesGoogleMapsSettingsType, ZidShipTypesPurchasableResponseInterface } from '../types';
export class ZidShipApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getServiceLevels(
    cityId: number,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidShipTypesServiceLevelResponseInterface>>> {
    const url = zidShipApiUrls.serviceLevels.replace('{city_id}', String(cityId));

    const response = this.apiClient.request<ApiBaseDataResponseObjectType<ZidShipTypesServiceLevelResponseInterface>>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response;
  }
  public async getServiceLevelCourierDetails(data: {
    serviceLevel: string;
    courierCode: string;
  }): Promise<ApiBaseDataResponseObjectType<ZidShipTypesModelServiceLevelCourierInterface>> {
    let url = zidShipApiUrls.serviceLevelCourierDetails.replace('{service_level}', data.serviceLevel);
    url = url.replace('{courier_code}', data.courierCode);
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidShipTypesModelServiceLevelCourierInterface>>(ApiHttpMethodEnum.get, url)
      .then((result) => result.data);
  }

  public async getZidshipServiceLevels(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ZidShipTypesServiceLevelResponseInterface>>
  > {
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ZidShipTypesServiceLevelResponseInterface>
    >(ApiHttpMethodEnum.get, zidShipApiUrls.zidshipServiceLevels, {
      cacheable: true,
    });

    return response;
  }

  public async getJourneyPages(): Promise<ZidShipTypesPagesResponseInterface> {
    return this.apiClient
      .request<ZidShipTypesPagesResponseInterface>(ApiHttpMethodEnum.get, zidShipApiUrls.journeyPages)
      .then((result) => result.data);
  }

  public async getWalletBallance(): Promise<ApiResponseType<ApiBaseResponseType<ZidShipTypesWalletBalance>>> {
    const response = this.apiClient.request<ApiBaseResponseType<ZidShipTypesWalletBalance>>(
      ApiHttpMethodEnum.get,
      zidShipApiUrls.walletBalance,
      { cacheable: true },
    );
    return response;
  }

  public async getZidShipStatus(): Promise<ApiBaseDataResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.get, zidShipApiUrls.zidshipStatus)
      .then((result) => result.data);
  }

  public async getWalletTransactions(
    filters: ZidShipTypesWalletFiltersInterface,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidShipTypesWalletTransactionsResponseInterface>>> {
    const params = urlQueryFormat(ZidShipTypesWalletFiltersToSearchParams(filters));

    const response = this.apiClient.request<
      ApiBaseDataResponseObjectType<ZidShipTypesWalletTransactionsResponseInterface>
    >(ApiHttpMethodEnum.get, zidShipApiUrls.walletTransactions, {
      params,
      cacheable: true,
    });

    return response;
  }

  public async getPackagesTransactions(
    filters: ZidShipTypesWalletFiltersInterface,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidShipTypesWalletTransactionsResponseInterface>>> {
    const params = urlQueryFormat(ZidShipTypesWalletFiltersToSearchParams(filters));

    const response = this.apiClient.request<
      ApiBaseDataResponseObjectType<ZidShipTypesWalletTransactionsResponseInterface>
    >(ApiHttpMethodEnum.get, zidShipApiUrls.packagesTransactions, {
      params,
      cacheable: true,
    });

    return response;
  }

  public async printTransactionsAttachment(
    transaction_numbers: string,
  ): Promise<ApiBaseDataResponseObjectType<string[]>> {
    const url = zidShipApiUrls.printTransactionsAttachment.replace('{transaction_numbers}', transaction_numbers);
    const response = this.apiClient.request<ApiBaseDataResponseObjectType<string[]>>(ApiHttpMethodEnum.get, url);

    return (await response).data;
  }

  public async activateServiceLevels({
    serviceLevel,
    inventoryAddressId,
  }: {
    serviceLevel: ZidShipTypesSelectedServiceLevels;
    inventoryAddressId: number | string | undefined;
  }): Promise<ApiBaseMessageResponseObjectType> {
    if (typeof inventoryAddressId == 'number') {
      const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(
        ApiHttpMethodEnum.post,
        zidShipApiUrls.activateServiceLevels,
        {
          data: {
            service_levels: serviceLevel,
            inventory_address_id: inventoryAddressId,
            is_single_service_level_activation: true,
          },
        },
      );

      return response.data;
    }

    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(
      ApiHttpMethodEnum.post,
      zidShipApiUrls.activateServiceLevels,
      {
        data: {
          service_levels: serviceLevel,
          inventory_location_id: inventoryAddressId,
          is_single_service_level_activation: true,
        },
      },
    );

    return response.data;
  }

  public async zidShipServiceActivate(request: ZidShipTypesServiceActivateRequestInterface): Promise<any> {
    const response = await this.apiClient.request<ApiBaseResponseType>(
      ApiHttpMethodEnum.post,
      zidShipApiUrls.activateServiceLevels,
      { data: request },
    );

    return response.data;
  }

  public async getServiceActivationFormData(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ZidShipTypesModelServiceActivationDataInterface>>
  > {
    const response = this.apiClient.request<
      ApiBaseDataResponseObjectType<ZidShipTypesModelServiceActivationDataInterface>
    >(ApiHttpMethodEnum.get, zidShipApiUrls.serviceActivationFormData, { cacheable: true });

    return response;
  }

  public async disableServiceLevels(data: {
    response_json?: boolean;
    service_level_code: string;
  }): Promise<ApiBaseMessageResponseObjectType> {
    return this.apiClient
      .request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, zidShipApiUrls.disableServiceLevels, { data })
      .then((result) => result.data);
  }

  public async getServiceLevelDetails(
    code: string,
  ): Promise<ApiBaseDataResponseObjectType<ZidShipTypesModelServiceLevelInterface>> {
    const url = zidShipApiUrls.serviceLevelDetails.replace('{code}', code);

    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidShipTypesModelServiceLevelInterface>>(ApiHttpMethodEnum.get, url)
      .then((result) => result.data);
  }

  public async getConfigurations({
    code,
    active,
  }: {
    code: string;
    active: boolean;
  }): Promise<ApiBaseDataResponseObjectType<ZidShipTypesModelConfigurationsInterface> & { message: string }> {
    const url = zidShipApiUrls.configurations.replace('{code}', code);
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidShipTypesModelConfigurationsInterface> & { message: string }>(
        ApiHttpMethodEnum.get,
        `${url}?active=${active}`,
      )
      .then((result) => result.data);
  }

  public async updateConfigurations(
    code: string,
    configurations: ZidShipTypesModelConfigurationsInterface,
  ): Promise<ApiBaseDataResponseObjectType<ZidShipTypesModelConfigurationsInterface> & { message: string }> {
    const url = zidShipApiUrls.configurations.replace('{code}', code);

    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidShipTypesModelConfigurationsInterface> & { message: string }>(
        ApiHttpMethodEnum.post,
        url,
        {
          data: configurations,
        },
      )
      .then((result) => result.data);
  }

  public async deleteConfigurations(
    code: string,
  ): Promise<ApiBaseDataResponseObjectType<ZidShipTypesModelConfigurationsInterface> & { message: string }> {
    const url = zidShipApiUrls.configurations.replace('{code}', code);

    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidShipTypesModelConfigurationsInterface> & { message: string }>(
        ApiHttpMethodEnum.delete,
        url,
      )
      .then((result) => result.data);
  }

  public async validateConfigurations(
    code: string,
    configurations: ZidShipTypesModelConfigurationsInterface,
  ): Promise<ApiBaseDataResponseObjectType<ZidShipTypesModelConfigurationsValidationInterface> & { message: string }> {
    const url = zidShipApiUrls.validateConfigurations.replace('{code}', code);

    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ZidShipTypesModelConfigurationsValidationInterface> & { message: string }>(
        ApiHttpMethodEnum.post,
        url,
        {
          data: configurations,
        },
      )
      .then((result) => result.data);
  }

  public async getPurchasables(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<Array<ZidShipTypesPurchasableResponseInterface>>>
  > {
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<Array<ZidShipTypesPurchasableResponseInterface>>
    >(ApiHttpMethodEnum.get, zidShipApiUrls.purchasables, { cacheable: true });

    return response;
  }

  public async payOnline(
    purchasables: Array<{ id: number | string; quantity: number }>,
    callbackUrl?: string,
  ): Promise<{ [key: string]: { credit_card: Record<string, number> } }> {
    return this.apiClient
      .request(ApiHttpMethodEnum.post, zidShipApiUrls.payOnline, {
        data: { purchasables, callbackUrl },
      })
      .then((result) => result.data);
  }

  public async bankTransfer(formData: FormData): Promise<ApiBaseResponseType> {
    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.post, zidShipApiUrls.bankTransfer, { data: formData })
      .then((result) => result.data);
  }

  public async getGoogleMapsSettings(): Promise<ZidShipTypesGoogleMapsSettingsType> {
    const response = await this.apiClient.request<
      ApiBaseResponseObjectType & { google_maps_settings: ZidShipTypesGoogleMapsSettingsType }
    >(ApiHttpMethodEnum.get, zidShipApiUrls.googleMapsSettings, {
      params: {
        response_type: 'json',
      },
    });

    return response.data.google_maps_settings;
  }

  public async getMerchantReports(): Promise<ApiBaseResponseType> {
    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.get, zidShipApiUrls.merchantReports)
      .then((result) => result.data);
  }

  public async getShipmentsSummary(): Promise<ApiBaseResponseType> {
    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.get, zidShipApiUrls.shipmentsSummary)
      .then((result) => result.data);
  }

  public async verifyIBAN(iban: string): Promise<ApiBaseResponseType<{ is_valid: boolean }>> {
    return this.apiClient
      .request<ApiBaseResponseType<{ is_valid: boolean }>>(ApiHttpMethodEnum.post, zidShipApiUrls.verifyIBAN, {
        data: { iban },
      })
      .then((result) => result.data);
  }

  public async sendOTP(phone_number: string): Promise<ApiBaseResponseType> {
    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.post, zidShipApiUrls.sendOTP, { data: { phone_number } })
      .then((result) => result.data);
  }

  public async verifyOTP(OTPInfo: {
    phoneNumber: string;
    code: string;
  }): Promise<ApiBaseResponseType<VerifyOtpResponseInterface>> {
    return this.apiClient
      .request<ApiBaseResponseType<VerifyOtpResponseInterface>>(ApiHttpMethodEnum.post, zidShipApiUrls.verifyOTP, {
        data: { phone_number: OTPInfo.phoneNumber, code: OTPInfo.code },
      })
      .then((result) => result.data);
  }

  public async returnShipment(order_id: number): Promise<ApiBaseDataResponseObjectType<ReturnedShipmentInfoInterface>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ReturnedShipmentInfoInterface>>(
        ApiHttpMethodEnum.post,
        zidShipApiUrls.returnShipment,
        { data: { order_id } },
      )
      .then((result) => result.data);
  }

  @Catch()
  public async checkPurchasedStatus(
    purchasableId: string,
  ): Promise<ApiBaseDataResponseObjectType<PurchasedResponseType> | ApiStatusResponseObjectType> {
    const url = zidShipApiUrls.checkPurchasedStatus;
    return this.apiClient
      .request(ApiHttpMethodEnum.post, url, {
        data: { id: purchasableId },
      })
      .then((result) => result.data);
  }

  public async fetchAllShipments(
    filters: ZidShipTypesShipmentsFilterInterface,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidShipShipmentsPaginationInterface>>> {
    const response = await this.apiClient.request(ApiHttpMethodEnum.get, zidShipApiUrls.zidshipShipments, {
      params: filters,
      cacheable: true,
    });
    return response;
  }

  public async shipmentsExports(trackingNumbers: Array<string>): Promise<ApiBaseDataResponseObjectType> {
    return this.apiClient
      .request(ApiHttpMethodEnum.post, zidShipApiUrls.zidshipShipmentsExport, { data: { numbers: trackingNumbers } })
      .then((result) => result.data);
  }

  @Catch()
  public async shipmentsFetchFilters(): Promise<
    ApiBaseDataResponseObjectType<ZidshipTypesShipmentsFiltersDateInterface>
  > {
    return this.apiClient
      .request(ApiHttpMethodEnum.get, zidShipApiUrls.zidshipShipmentsFilters)
      .then((result) => result.data);
  }

  public async updateZidshipInventoryAddress(id: number | string): Promise<ApiDescriptionDataResponseObjectType> {
    const url = zidShipApiUrls.updateZidshipInventoryAddress;

    if (typeof id == 'number') {
      const response = await this.apiClient.request<ApiDescriptionDataResponseObjectType>(ApiHttpMethodEnum.post, url, {
        data: { ['inventory_address_id']: id },
      });
      return response.data;
    }

    const response = await this.apiClient.request<ApiDescriptionDataResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data: { ['inventory_location_id']: id },
    });
    return response.data;
  }
}
