export enum MixpanelEvents {
  clickEmailRegister = 'Click Email register',
  clickGoogleRegister = 'Click Google register',
  visitRegistrationPage = 'Visit Registration Page',
  clickLogin = 'Click Login',
  fillEmail = 'Fill Email',
  clickSendOTP = 'Click send OTP',
  fillOTP = 'Fill OTP',
  clickVerify = 'Click verify',
  verificationStatus = 'Verification Status',
  visitPreparingPage = 'Visit Preparing Page',
  clickSubmitStoreName = 'Click submit store name',
  submitMobileNumber = 'Submit mobile number',
  fillCategory = 'Fill Category',
  submitCategory = 'Submit Category',
  chooseLocationType = 'Choose Location type',
  submitLocation = 'Submit Location',
  chooseBusinessType = 'Choose business type',
  clickCreateStore = 'Click Create store',
  visitSubscriptionPage = 'Visit subscription page',
  choosePackageDuration = 'Choose Package Duration',
  choosePackage = 'Choose Package',
  visitPaymentPage = 'Visit payment page',
  completePayment = 'Complete payment',
  clickEnglishLanguage = 'Click English language',
  clickArabicLanguage = 'Click Arabic language',
  visitOrdersPage = 'Visit Orders Page',
  clickDrafts = 'Click Drafts',
  clickOrdersStatusMessages = 'Click Orders status messages',
  clickCreateNewOrder = 'Click Create new order',
  clickAnalytics = 'Click Analytics',
  chooseOrdersFilter = 'Choose orders filter',
  chooseNumberOfOrdersDisplayedFilter = 'Choose number of orders displayed filter',
  searchFilter = 'Search filter',
  clickExportSllOrders = 'Click Export all orders',
  cancelExportOrders = 'Cancel export orders',
  editEmailExportOrders = 'edit email - export orders',
  confirmExportOrders = 'Confirm export orders',
  clickPrintWaybill = 'Click print waybill',
  clickChangeOrderStatus = 'Click Change order status',
  clickChangePaymentStatus = 'Click Change payment status',
  clickDetermineDateOfReceivingTheOrder = 'Click determine date of receiving the order',
  selectOrder = 'Select order',
  openOrderDetailesSlideCard = 'open order detailes slide card',
  clickOrderId = 'click order id',
  viewDraftsPage = 'view drafts page',
  clickAddNewDraft = 'Click add new draft',
  viewAddingProductPage = 'View adding product page',
  searchProduct = 'search product',
  addSelectedProducts = 'add selected products',
  changeQuantity = 'change quantity',
  removeProduct = 'remove product',
  clickNext = 'Click next',
  viewCustomerInformationPage = 'View customer information page',
  searchCustomer = 'search customer',
  addNewCustomer = 'add new customer',
  addNewAddress = 'add new address',
  chooseAddress = 'choose address',
  chooseDifferentOrderReciever = 'choose different order reciever',
  viewPaymentShippingMethodPage = 'View payment & shipping method page',
  selectPaymentMethod = 'select payment method',
  viewOrderSummaryPage = 'View Order Summary page',
  clickConfirmation = 'Click confirmation',
  visitProductPage = 'Visit Product Page',
  clickProductFiltrationSettings = 'Click Product Filtration Settings',
  visitProductFiltrationSettingsPage = 'Visit Product Filtration Settings page',
  clickProductCategories = 'Click Product Categories',
  visitProductCategories = 'Visit Product Categories',
  chooseAddNewProduct = 'Choose add new product',
  chooseAddNewDigitalProduct = 'Choose add new digital product',
  clickNormalProductsView = 'Click normal products view',
  clickDigitalProductsView = 'Click digital products view',
  clickArrangeManually = 'Click Arrange Manually',
  clickFilterProducts = 'Click Filter Products',
  chooseExportSelectedProducts = 'Choose Export selected products',
  chooseExportAllProducts = 'Choose Export all products',
  chooseEditSelectedProducts = 'Choose Edit selected products',
  chooseEditSllProducts = 'Choose Edit all products',
  chooseDeleteProducts = 'Choose delete products',
  selectAProduct = 'Select a product',
  chooseDuplicateAProduct = 'Choose duplicate a product',
  chooseDeleteAProduct = 'Choose delete a product',
  chooseCustomizeSimilarProducts = 'Choose customize similar products',
  chooseEditAProduct = 'Choose Edit a product',
  clickOnAProduct = 'Click on a product',
  clickImportProducts = 'Click import products',
  clickCustomerName = 'Click customer name',
  clickProductsSales = 'Click Products sales',
  openProductInfoCard = 'Open product info card',
  globalSearch = 'Global search',
  clickExportProducts = 'Click Export Products',
  clickAddNewProduct = 'Click add new product',
  clickMoreAction = 'Click more action',
  clickOnaProductActions = 'Click on a product actions',
  clickVerifyEmailOTP = 'Click verify Email OTP',
  clickVerifyPhoneOTP = 'Click verify Phone OTP',
  clickZamMainHelpCenter = 'Click ZAM main help center',
  clickZamMainAppsManagement = 'Click ZAM main apps management',
  clickZamMainAppsSearch = 'Click ZAM main apps search',
  clickZamScrollFeaturedApps = 'ZAM scroll featured apps',
  clickZamFeaturedApp = 'Click ZAM featured app',
  clickZamScrollAppTestimonials = 'ZAM scroll app testimonials',
  clickZamAllCategories = 'Click ZAM all categories',
  clickZamViewCategory = 'Click ZAM view category',
  clickZamChoiceApp = 'Click ZAM choice app',
  clickZamSuggestApp = 'Click ZAM suggest app',
  clickZamPartnerRegisteration = 'Click ZAM partner registration',
  enableZAMRecurring = 'Enable ZAM App recurring',
  clickZamBrowseApps = 'Click ZAM browse apps',
  clickZamActivateApp = 'Click ZAM activate app',
  clickZamDeactivateApp = 'Click ZAM deactivate app',
  clickZamSearchAppCategory = 'Click ZAM search app category',
  sortZamApps = 'Sort ZAM apps',
  clickZamAppViewPlans = 'Click ZAM app view plans',
  clickZamAppViewReviews = 'Click ZAM app view reviews',
  clickZamViewSubscriptionPlans = 'Click ZAM app view subscription plans',
  clickZamSubscribeToTrialPlan = 'Click ZAM subscribe to trial plan',
  clickZamSubscribeToFreePlan = 'Click ZAM subscribe to free plan',
  clickZamSubscribeToPlan = 'Click ZAM subscribe to plan',
  enableZAMPlanRecurring = 'Enable ZAM Plan recurring',
  clickZamMoveToPayment = 'Click ZAM move to payment',
  clickZamUpgradePlan = 'Click ZAM upgrade plan',
  clickZamOpenApp = 'Click ZAM open app',
  clickZamReportAppIssue = 'Click ZAM report app issue',
  rateZamApp = 'Rate ZAM app',
  clickZamScrollAppCollections = 'ZAM scroll app collections',
  clickZamViewCollection = 'Click ZAM view collection',
  zamPaymentSuccess = 'ZAM payment success',
  zamPaymentFailure = 'ZAM payment failure',
}
